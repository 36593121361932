import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Divider, Grid, Paper, Typography } from '@mui/material'
import { IsMobileWidth, IsTabletWidth } from '../../../components/common/utill/utils';
import clsx from 'clsx';
import { useStyle } from './create.token.form.style';
import { useMetaMask } from "metamask-react";
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import CircularImageCard from '../../../components/common/circular.image.card/circular.image.card';
import MaterialSelectDropdown from '../../../components/common/material.select.dropdown/material.select.dropdown';
import SiteLabelTextField from "../../../components/common/site.label.textfield/site.label.textfield";
import SwitchButton from '../switch.button/switch.button';
import TermsAndConditionSelectionDialog from '../../../components/common/terms.and.conditions.selection.dialog/terms.and.conditions.selection.dialog';
import { isFormDetails } from './validator';
import AmazingKRC20 from '../../../config/abis/AmazingKRC20.json';
import BurnableKRC20 from '../../../config/abis/BurnableKRC20.json';
import CommonKRC20 from '../../../config/abis/CommonKRC20.json';
import DeflationaryKRC20 from '../../../config/abis/DeflationaryKRC20.json';
import HelloKRC20 from '../../../config/abis/HelloKRC20.json';
import MintableKRC20 from '../../../config/abis/MintableKRC20.json';
import PausableKRC20 from '../../../config/abis/PausableKRC20.json';
import SimpleKRC20 from '../../../config/abis/SimpleKRC20.json';
import StandardKRC20 from '../../../config/abis/StandardKRC20.json';
import TaxableKRC20 from '../../../config/abis/TaxableKRC20.json';
import UnlimitedKRC20 from '../../../config/abis/UnlimitedKRC20.json';
import PowerfulKRC20 from '../../../config/abis/PowerfulKRC20.json';


import setting from '../../../config/setting.json'
const CreateTokenForm = (props) => {

    const { label, description, title, abi } = props;
    const TOKEN_TYPE = [
        {
            name: "HelloKRC20",
            value: 'HELLO_KRC20',
        },
        {
            name: "SimpleKRC20",
            value: "SIMPLE_KRC20",
        },
        {
            name: "StandardKRC20",
            value: "STANDARD_KRC20",
        },
        {
            name: "BurnableKRC20",
            value: 'BURNABLE_KRC20',
        },
        {
            name: "MintableKRC20",
            value: "MINTBLE_KRC20",
        },
        {
            name: "PausableKRC20",
            value: "PAUSABLE_KRC20",
        },
        {
            name: "CommonKRC20",
            value: "COMMON_KRC20",
        },
        {
            name: "DeflationaryKRC20",
            value: "DEFLATIONARY_KRC20",
        },
        {
            name: "TaxableKRC20",
            value: 'TAXABLE_KRC20',
        },
        {
            name: "UnlimitedKRC20",
            value: "UNLIMITED_KRC20",
        },
        {
            name: "AmazingKRC20",
            value: "AMAZING_KRC20",
        },
        {
            name: "PowerfulKRC20",
            value: "POWERFUL_KRC20",
        }
    ]
    const NETWORK_TYPE = [
        {
            name: 'Kaichain Main Network',
            value: '0xefde'
        },
        {
            name: 'Kaichain Test Network',
            value: '0x7360'
        }
    ]
    const [state, setState] = useState({
        tokenType: 'HELLO_KRC20',
        network: '0xefde',
        // price: '',
        tokenName: '',
        tokenSymbol: '',
        tokenDecimals: 18,
        initialSupply: 10000,
        maxSupply: 10000,
        burnable: false,
        mintable: false,
        tokenRecover: false,
        deflationary: false,
        taxable: false,
        KRC1363: false,
        supplyType: 'Fixed',
        accessType: 'None',
        transferType: 'Unstoppable',
        burnFee: '',
        taxFee: '',
        taxAddress: '',
        fee: 0,
        viewSourceCode: true,
        removeCopyright: false,
        isDisabledTokenDecimal: true,
        isDisabledInitialSupply: true,
        isDisabledMaxSupply: true,
        data: HelloKRC20,
        validationResult: {
            isValidTokenName: true,
            isValidTokenSymbol: true,
            isValidTokenDecimals: true,
            isValidInitialSupply: true,
            isValidMaxSupply: true,
            isValidBurnFee: true,
            isValidTaxFee: true,
            isValidTaxAddress: true
        },

    })
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const classes = useStyle()
    const { status, connect, account, chainId, ethereum, switchChain } = useMetaMask();
    useEffect(() => {
        console.log(status)
    }, [status])


    const SendFormData = async (e) => {
        e.preventDefault();
        const validationResult = isFormDetails(state);
        setState({ ...state, validationResult: validationResult });
        console.log(state)
        console.log(validationResult.allValid);
        if (validationResult.allValid) {

            if (status === "notConnected") {
                (async () => {
                    try {
                        await connect();
                    } catch (error) {
                        console.log(error);
                    }
                })();
            }
            if (status === "connected") {

                if (chainId != state.network) {
                    (async () => {
                        try {
                            await switchChain(state.network);
                        } catch (error) {
                            console.log(error);
                        }
                    })();
                }
                let argument;
                let cap;
                switch (state.tokenType) {
                    case "HELLO_KRC20":
                        argument = [state.tokenName, state.tokenSymbol, setting.feeAddress];
                        break;
                    case "SIMPLE_KRC20":
                        argument = [state.tokenName, state.tokenSymbol, state.initialSupply, setting.feeAddress];
                        break;
                    case "STANDARD_KRC20":
                        argument = [state.tokenName, state.tokenSymbol, state.initialSupply, state.tokenDecimals, setting.feeAddress];
                        break;
                    case "BURNABLE_KRC20":
                        argument = [state.tokenName, state.tokenSymbol, state.initialSupply, state.tokenDecimals, setting.feeAddress];
                        break;
                    case "MINTBLE_KRC20":
                        argument = [state.tokenName, state.tokenSymbol, state.initialSupply, state.tokenDecimals, BigNumber(10 ** state.tokenDecimals * state.initialSupply), setting.feeAddress];
                        break;
                    case "PAUSABLE_KRC20":
                        argument = [state.tokenName, state.tokenSymbol, state.initialSupply, state.tokenDecimals, setting.feeAddress];
                        break;
                    case "COMMON_KRC20":
                        argument = [state.tokenName, state.tokenSymbol, state.initialSupply, state.tokenDecimals,  BigNumber(10 ** state.tokenDecimals * state.initialSupply), setting.feeAddress];
                        break;
                    case "DEFLATIONARY_KRC20":
                        argument = [state.tokenName, state.tokenSymbol, state.initialSupply, state.tokenDecimals, state.burnFee,  setting.feeAddress];
                        break;
                    case "TAXABLE_KRC20":
                        argument = [state.tokenName, state.tokenSymbol, state.initialSupply, state.tokenDecimals, state.taxAddress, state.taxFee, setting.feeAddress];
                        break;
                    case "UNLIMITED_KRC20":
                        argument = [state.tokenName, state.tokenSymbol, state.initialSupply, state.tokenDecimals, setting.feeAddress];
                        break;
                    case "AMAZING_KRC20":
                        argument = [];
                        break;
                    case "POWERFUL_KRC20":
                        argument = [];
                        break;
                    default:
                        argument = [state.tokenName, state.tokenSymbol, setting.feeAddress];
                        break;
                }
                let web3 = new Web3(ethereum);
                try {
                    let contract = new web3.eth.Contract(state.data.abi);
                    console.log(argument);
                    let gas = await contract.deploy({
                        data: state.data.bytecode,
                        arguments: argument
                    }).send({ from: account, value: state.fee });
                    console.log(gas);
                } catch (error) {
                    console.log(error);
                }
            }

        }
    };

    const onChange = (name, value) => {
        setState({ ...state, [name]: value })

    }
    useEffect(() => {
        if (state.tokenType === 'HELLO_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, initialSupply: 1000,
                maxSupply: 1000, burnable: false, mintable: false, tokenRecover: false, deflationary: false,
                taxable: false, KRC1363: false, supplyType: '10k', accessType: 'None', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: false, isDisabledTokenDecimal: true,
                isDisabledInitialSupply: true, isDisabledMaxSupply: true,
                arguments: [],
                data: HelloKRC20
            })
        }
        else if (state.tokenType === 'SIMPLE_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: false, mintable: false, tokenRecover: false, deflationary: false,
                taxable: false, KRC1363: false, supplyType: 'Fixed', accessType: 'None', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: false, isDisabledTokenDecimal: true,
                isDisabledInitialSupply: false, isDisabledMaxSupply: true,
                arguments: [],
                data: SimpleKRC20
            })
        }
        else if (state.tokenType === 'STANDARD_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: false, mintable: false, tokenRecover: false, deflationary: false,
                taxable: false, KRC1363: false, supplyType: 'Fixed', accessType: 'None', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: true, isDisabledTokenDecimal: false,
                isDisabledInitialSupply: false, isDisabledMaxSupply: true,
                arguments: [],
                data: StandardKRC20
            })
        }
        else if (state.tokenType === 'BURNABLE_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: true, mintable: false, tokenRecover: false, deflationary: false,
                taxable: false, KRC1363: false, supplyType: 'Fixed', accessType: 'None', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: true, isDisabledTokenDecimal: false,
                isDisabledInitialSupply: false, isDisabledMaxSupply: true,
                arguments: [],
                data: BurnableKRC20
            })
        }
        else if (state.tokenType === 'MINTBLE_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: false, mintable: true, tokenRecover: false, deflationary: false,
                taxable: false, KRC1363: false, supplyType: 'Capped', accessType: 'Ownable', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: true, isDisabledTokenDecimal: false,
                isDisabledInitialSupply: false, isDisabledMaxSupply: true,
                arguments: [],
                data: MintableKRC20
            })
        }
        else if (state.tokenType === 'PAUSABLE_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: false, mintable: false, tokenRecover: false, deflationary: false,
                taxable: false, KRC1363: false, supplyType: 'Fixed', accessType: 'Ownable', transferType: 'Pausable',
                viewSourceCode: true, removeCopyright: true, isDisabledTokenDecimal: false,
                isDisabledInitialSupply: false, isDisabledMaxSupply: true,
                arguments: [],
                data: PausableKRC20
            })
        }
        else if (state.tokenType === 'COMMON_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: true, mintable: true, tokenRecover: false, deflationary: false,
                taxable: false, KRC1363: false, supplyType: 'Capped', accessType: 'Ownable', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: true, isDisabledTokenDecimal: false,
                isDisabledInitialSupply: false, isDisabledMaxSupply: false,
                arguments: [],
                data: CommonKRC20
            })
        }
        else if (state.tokenType === 'DEFLATIONARY_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: false, mintable: false, tokenRecover: false, deflationary: true,
                taxable: false, KRC1363: false, supplyType: 'Fixed', accessType: 'Ownable', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: true, isDisabledTokenDecimal: false,
                isDisabledInitialSupply: false, isDisabledMaxSupply: true, burnFee: '',
                arguments: [],
                data: DeflationaryKRC20
            })
        }
        else if (state.tokenType === 'TAXABLE_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: false, mintable: false, tokenRecover: false, deflationary: false,
                taxable: true, KRC1363: false, supplyType: 'Fixed', accessType: 'Ownable', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: true, isDisabledTokenDecimal: false,
                isDisabledInitialSupply: false, isDisabledMaxSupply: true, burnFee: '', taxFee: '',
                arguments: [],
                data: TaxableKRC20
            })
        }
        else if (state.tokenType === 'UNLIMITED_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: true, mintable: true, tokenRecover: false, deflationary: false,
                taxable: false, KRC1363: false, supplyType: 'Unlimited', accessType: 'Role Based', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: true, isDisabledTokenDecimal: false,
                isDisabledInitialSupply: false, isDisabledMaxSupply: true,
                arguments: [],
                data: UnlimitedKRC20
            })
        }
        else if (state.tokenType === 'AMAZING_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: true, mintable: true, tokenRecover: true, deflationary: false,
                taxable: false, KRC1363: true, supplyType: 'Unlimited', accessType: 'Ownable', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: true, isDisabledTokenDecimal: false,
                isDisabledInitialSupply: false, isDisabledMaxSupply: true,
                arguments: [],
                data: AmazingKRC20
            })
        }
        else if (state.tokenType === 'POWERFUL_KRC20') {
            setState({
                ...state, tokenName: '', tokenSymbol: '', tokenDecimals: 18, burnable: true, mintable: true, tokenRecover: true, deflationary: false,
                taxable: false, KRC1363: true, supplyType: 'Capped', accessType: 'Role Based', transferType: 'Unstoppable',
                viewSourceCode: true, removeCopyright: true, isDisabledTokenDecimal: false,
                isDisabledInitialSupply: false, isDisabledMaxSupply: true,
                arguments: [],
                data: PowerfulKRC20
            })
        }
    }, [state.tokenType])


    const onChangeNetwork = (name, value) => {
        setState({ ...state, [name]: value })

    }

    return (
        <div className={clsx(!(mobileWidth || tabletWidth) && 'pt-5 pb-5', (mobileWidth || tabletWidth) && 'pt-3 pb-3')}>
            <Container maxWidth={clsx(!mobileWidth && 'lg', mobileWidth && 'sm')}>
                {
                    status === "unavailable" &&
                    <div>
                        <div className="alert alert-danger p-4" role="alert">
                            <Typography variant='h6'>Alert</Typography>
                            <Typography variant='body2' className='pt-2'> To use this app please install <a href='https://metamask.io/'> MetaMask</a>. Use any other wallet at your own risk.</Typography>
                        </div>
                        <div className="pt-2 pb-2">
                            <Typography className={clsx(classes.textStyle, 'pt-2 pb-2')}
                                variant={clsx(!(mobileWidth || tabletWidth) && 'h5', tabletWidth && 'subtitle20M6', mobileWidth && 'subtitle')}>
                                {title ? title : ''}
                            </Typography>
                            {label ?
                                <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'body2')} className={clsx(classes.textStyle, 'mt-3 pt-2 text-transform-none')}>
                                    {label}
                                </Typography>
                                : null}
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'body2')} className={clsx(classes.textStyle, 'pt-2')}>
                                {description ? description : ''}
                            </Typography>
                        </div>
                    </div>
                }


                <Grid container rowSpacing={2} spacing={5} className={clsx(!mobileWidth && 'mt-4 mb-4', mobileWidth && 'mt-2 mb-2')}>
                    <Grid item xs={12} sm={12} md={12} lg={4} className='d-flex justify-content-start'>
                        <MaterialSelectDropdown
                            data={TOKEN_TYPE}
                            value={state.tokenType}
                            onChange={(e) => onChange('tokenType', e.target.value)}
                            topAdornment="Token type"
                            message='Choose a Token Type.'
                            showSteric={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} className='d-flex justify-content-start'>
                        <MaterialSelectDropdown
                            data={NETWORK_TYPE}
                            value={state.network}
                            onChange={(e) => onChangeNetwork('network', e.target.value)}
                            topAdornment="Network"
                            message='Choose a network.'
                            showSteric={true}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12} lg={4} className='d-flex justify-content-start'>
                        <MaterialSelectDropdown
                            data={USER_TYPE}
                            value={state.network}
                            onChange={(e) => onChange('network', e.target.value)}
                            topAdornment="Network"
                            error='Network is required'
                            message='Choose a network.'
                            showSteric={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} className='d-flex justify-content-start'>
                        <SiteLabelTextField
                            topAdornment="Price"
                            validationType='NUMERIC'
                            placeholder="0.03 KEC"
                            value={state.price}
                            onChange={(e) => onChange("price", e.target.value)}
                            error='Price is required'
                            // error={!state.validationResult.isValidEmail}
                            message='10k, Fixed, Unlimited, Capped'
                            disabled={true}
                        />
                    </Grid>*/}
                </Grid>
                <Divider variant='fullWidth' light={true} orientation='horizontal' />
                <Grid container rowSpacing={2} spacing={5} className={clsx(!mobileWidth && 'mt-2 mb-2', mobileWidth && 'mt-2 mb-2')}>
                    <Grid item xs={12} sm={12} md={12} lg={4} className='d-flex flex-column justify-content-start'>
                        <div className='mt-2 mb-2'>
                            <SiteLabelTextField
                                topAdornment="Token name"
                                placeholder="e.g.My Token"
                                showSteric={true}
                                value={state.tokenName}
                                onChange={(e) => onChange("tokenName", e.target.value)}
                                error={!state.validationResult.isValidTokenName ? 'The token name field is required' : ''}
                                message='A custom name for the token.'
                            />
                        </div>
                        <div className='mt-2 mb-2'>
                            <SiteLabelTextField
                                topAdornment="Token Symbol"
                                placeholder="e.g.MYT"
                                value={state.tokenSymbol}
                                showSteric={true}
                                onChange={(e) => onChange("tokenSymbol", e.target.value)}
                                error={!state.validationResult.isValidTokenSymbol ? 'The token symbol field is required' : ''}
                                message='A custom symbol for the token (usually 3-5 chars).'
                            />
                        </div>
                        <div className='mt-2 mb-2'>
                            <SiteLabelTextField
                                topAdornment="Token decimals"
                                placeholder="18"
                                showSteric={true}
                                value={state.tokenDecimals}
                                validationType='NUMERIC'
                                onChange={(e) => onChange("tokenDecimals", e.target.value)}
                                error={!state.validationResult.isValidTokenDecimals ? 'The token decimal field is required' : ''}
                                disabled={state.isDisabledTokenDecimal}
                                message='The decimal precision for the token (usually 18).'
                            />
                        </div>
                        <div className='mt-2 mb-2'>
                            <SiteLabelTextField
                                topAdornment="Initial Supply"
                                showSteric={true}
                                placeholder="e.g. 21000000"
                                value={state.initialSupply}
                                validationType='NUMERIC'
                                onChange={(e) => {
                                    if (e.target.value > 10 ** 18) {
                                        return;
                                    } else {
                                        onChange("initialSupply", e.target.value)
                                    }
                                }}
                                error={!state.validationResult.isValidInitialSupply ? 'The token initial supply field may only contain numeric characters' : ''}
                                disabled={state.isDisabledInitialSupply}
                                message='The initial number of available tokens that will be created in your account.'
                            />
                        </div>
                        {state.tokenType === 'UNLIMITED_KRC20' || state.tokenType === 'AMAZING_KRC20' || state.tokenType === 'POWERFUL_KRC20' ?
                            null :
                            <div className='mt-2 mb-2'>
                                <SiteLabelTextField
                                    showSteric={true}
                                    topAdornment="Max Supply"
                                    placeholder="e.g. 21000000"
                                    value={state.tokenType === 'COMMON_KRC20' ? state.maxSupply : state.initialSupply}
                                    onChange={(e) => onChange("maxSupply", e.target.value)}
                                    error={!state.validationResult.isValidMaxSupply ? 'The token max supply field may only contain numeric characters' : ''}
                                    message='The maximum number of tokens available.'
                                    disabled={state.isDisabledMaxSupply}
                                />
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} className='d-flex flex-column justify-content-start'>
                        <div className='mt-1 mb-1'>
                            <SwitchButton
                                disabled={true}
                                topAdornment="Features"
                                value={state.burnable}
                                onChange={(e) => onChange("burnable", e.target.checked)}
                                // error='The token name field is required'
                                message='The Token can be manually burned to reduce circulating supply.'
                                label='Burnable'
                            />
                        </div>
                        <div className='mt-1 mb-1'>
                            <SwitchButton
                                disabled={state.tokenType === 'MINTBLE_KRC20' ? false : true}
                                value={state.mintable}
                                onChange={(e) => onChange("mintable", e.target.checked)}
                                // error='The token name field is required'
                                message='Owner or accounts with minting permission will be able to generate new tokens, up to token max supply, by minting them.'
                                label='Mintable'
                            />
                        </div>
                        <div className='mt-1 mb-1'>
                            <SwitchButton
                                disabled={true}
                                value={state.deflationary}
                                onChange={(e) => onChange("deflationary", e.target.checked)}
                                // error='The token name field is required'
                                message='Token supply will reduce over time. For each transaction a fee will be automatically burned. Owner can exclude some accounts from the deflation mechanism.'
                                label='Deflationary'
                            />
                        </div>
                        {state.tokenType === 'DEFLATIONARY_KRC20' ?
                            <div className='mt-1 mb-1'>
                                <SiteLabelTextField
                                    topAdornment="Burn Fee (per mille)"
                                    placeholder="e.g. 50"
                                    value={state.burnFee}
                                    showSteric={true}
                                    onChange={(e) => onChange("burnFee", e.target.value)}
                                    message='To give greater accuracy enter an amount per mille so, for example, to set a 5% burn fee, enter 50. Owner can change this later. By default owner is excluded from burn fee.'
                                    // disabled={true}
                                    endAdornment={<Typography>%</Typography>}
                                    error={!state.validationResult.isValidBurnFee ? 'The burn fee field is required' : ''}

                                />
                            </div> : null}
                        <div className='mt-1 mb-1'>
                            <SwitchButton
                                disabled={true}
                                value={state.taxable}
                                onChange={(e) => onChange("taxable", e.target.checked)}
                                message='A tax fee will be added to transfers. For each transaction a fee will be automatically sent to a predefined address. Owner can exclude some accounts from the taxation mechanism.'
                                label='Taxable'
                            />
                        </div>
                        {state.tokenType === 'TAXABLE_KRC20' ?
                            <div>
                                <div className='mt-1 mb-1'>
                                    <SiteLabelTextField
                                        topAdornment="Tax Fee (per mille)"
                                        showSteric={true}
                                        placeholder="e.g. 50"
                                        value={state.taxFee}
                                        onChange={(e) => onChange("taxFee", e.target.value)}
                                        message='To give greater accuracy enter an amount per mille so, for example, to set a 5% tax fee, enter 50. Owner can change this later. By default owner is excluded from tax fee.'
                                        endAdornment={<Typography>%</Typography>}
                                        error={!state.validationResult.isValidTaxFee ? 'The Tax fee field is required' : ''}
                                    />
                                </div>
                                <div className='mt-1 mb-1'>
                                    <SiteLabelTextField
                                        topAdornment="Tax Address "
                                        showSteric={true}
                                        placeholder="0x123456789..."
                                        value={state.taxAddress}
                                        onChange={(e) => onChange("taxAddress", e.target.value)}
                                        message='The address where tax fees will be collected. Owner can change this later. By default this address is excluded from tax fee.'
                                        endAdornment={<Typography>%</Typography>}
                                        error={!state.validationResult.isValidTaxAddress ? 'The Tax Address field is required' : ''}
                                    />
                                </div>
                            </div>
                            : null
                        }
                        <div className='mt-1 mb-1'>
                            <SwitchButton
                                disabled={true}
                                value={state.KRC1363}
                                onChange={(e) => onChange("KRC1363", e.target.checked)}
                                // error='The token name field is required'
                                message='The ERC1363 Token is an extension of KRC20 that can make a callback on the receiver contract to notify token transfers or token approvals.'
                                label='KRC1363'
                            />
                        </div>
                        <div className='mt-1 mb-1'>
                            <SwitchButton
                                disabled={true}
                                value={state.tokenRecover}
                                onChange={(e) => onChange("tokenRecover", e.target.checked)}
                                // error='The token name field is required'
                                message='It allows the contract owner to recover any KRC20 token sent into the contract for error.'
                                label='Token Recover'
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} className='d-flex flex-column justify-content-start'>
                        <div className='mt-2 mb-2'>
                            <SiteLabelTextField
                                topAdornment="Supply Type"
                                placeholder="Fixed"
                                value={state.supplyType}
                                onChange={(e) => onChange("supplyType", e.target.value)}
                                message='10k, Fixed, Unlimited, Capped'
                                disabled={true}
                                endAdornment={<img src={`${process.env.PUBLIC_URL}/assets/images/unfold_more_arrows.svg`} width='20px' />}
                            />
                        </div>
                        <div className='mt-2 mb-2'>
                            <SiteLabelTextField
                                topAdornment="Access Type"
                                placeholder="None"
                                value={state.accessType}
                                onChange={(e) => onChange("accessType", e.target.value)}
                                endAdornment={<img src={`${process.env.PUBLIC_URL}/assets/images/unfold_more_arrows.svg`} width='20px' />}
                                disabled={true}
                                message='None, Ownable, Role Based'
                            />
                        </div>
                        <div className='mt-2 mb-2'>
                            <SiteLabelTextField
                                topAdornment="Transfer Type"
                                placeholder="Unstoppable"
                                value={state.transferType}
                                onChange={(e) => onChange("transferType", e.target.value)}
                                endAdornment={<img src={`${process.env.PUBLIC_URL}/assets/images/unfold_more_arrows.svg`} width='20px' />}
                                disabled={true}
                                message='Unstoppable, Pausable'
                            />
                        </div>
                        <div className='mt-1 mb-1'>
                            <SwitchButton
                                disabled={true}
                                value={state.viewSourceCode}
                                onChange={(e) => onChange("viewSourceCode", e.target.checked)}
                                message='Token Source Code will be automatically verified on Etherscan. Only available on Mainnet.'
                                label='Verified Source Code'
                            />
                        </div>
                        <div className='mt-1 mb-1'>
                            <SwitchButton
                                disabled={true}
                                value={state.removeCopyright}
                                onChange={(e) => onChange("removeCopyright", e.target.checked)}
                                message='Remove the link pointing to this page from Token contract.'
                                label='Remove Copyright'
                            />
                        </div>
                        <Box className="d-flex justify-content-center mt-2 mb-2">
                            <TermsAndConditionSelectionDialog
                                onChange={(label, value) => onChange(label, value)}
                                {...props}
                            />
                        </Box>
                        <Button onClick={SendFormData} variant='contained' color='primary' className='pt-3 pb-3 mt-2'>
                            Next
                        </Button>
                    </Grid>
                </ Grid>
                {/* <div className="pt-5 pb-4 text-center">
                    <Button className='pt-3 pb-3 pl-5 pr-5' variant='outlined' color='secondary'>
                        Get started
                    </Button>
                </div> */}
            </Container>
        </div >
    )
}
export default CreateTokenForm